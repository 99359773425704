import React from 'react';
import dynamic from 'next/dynamic';
import commonRoutes from '../../routes/common';
import { ForbiddenAddress } from './ForbiddenAddress';
import { useRouter } from 'next/router';

const DynamicPublicLayout = dynamic(() => import('./DynamicPublicLayout'));
const LoggedInLayout = dynamic(() => import('./LoggedInLayout'));
const AppLoader = dynamic(() => import('../elements/AppLoader'));

const getRedirectLink = url => {
  const uri = url.split('?returnUrl=');
  return uri.length === 2 ? decodeURIComponent(uri[1]) : commonRoutes.home.to;
};

const getLoginLink = url => `${commonRoutes.general.login.to}?returnUrl=${url}`;
const hasReturnUrl = url => url.includes('returnUrl=');

export const buildGuestOrLoggedInLayout = () => user => {
  if (!user || user.isLoading) return AppLoader;
  return user.isLoggedIn() ? LoggedInLayout : DynamicPublicLayout;
};

export const buildGuestOnlyLayout =
  (publicLayout = DynamicPublicLayout) =>
    user => {
      const path = useRouter().asPath;
      if (!user || user.isLoading) return AppLoader;
      if (user.isLoggedIn()) {
        throw new ForbiddenAddress({
          redirectAddress: getRedirectLink(path),
          hasReturnUrl: hasReturnUrl(path)
        });
      }
      return publicLayout;
    };

export const buildLoggedInOnlyLayout =
  (privateLayout = LoggedInLayout) =>
    user => {
      const path = useRouter().asPath;
      if (!user || user.isLoading) return AppLoader;
      if (!user.isLoggedIn()) {
        throw new ForbiddenAddress({
          redirectAddress: getLoginLink(path),
          hasReturnUrl: hasReturnUrl(path)
        });
      }
      return privateLayout;
    };

export const buildFreelancerOnlyLayout =
  (privateLayout = LoggedInLayout) =>
    user => {
      const path = useRouter().asPath;
      if (!user || user.isLoading) return AppLoader;
      if (!user.isLoggedIn()) {
        throw new ForbiddenAddress({
          redirectAddress: getLoginLink(path),
          hasReturnUrl: hasReturnUrl(path)
        });
      }
      if (!user.isFreelance()) {
        throw new ForbiddenAddress({
          redirectAddress: commonRoutes.home.to
        });
      }
      return privateLayout;
    };

export const buildClientOnlyLayout =
  (privateLayout = LoggedInLayout) =>
    user => {
      const path = useRouter().asPath;
      if (!user || user.isLoading) return AppLoader;
      if (!user.isLoggedIn()) {
        throw new ForbiddenAddress({
          redirectAddress: getLoginLink(path),
          hasReturnUrl: hasReturnUrl(path)
        });
      }
      if (!user.isClient()) {
        throw new ForbiddenAddress({
          redirectAddress: commonRoutes.home.to
        });
      }
      return privateLayout;
    };

export const buildCorporateOnlyLayout =
  (privateLayout = LoggedInLayout) =>
    user => {
      const path = useRouter().asPath;
      if (!user || user.isLoading) return AppLoader;
      if (!user.isLoggedIn()) {
        throw new ForbiddenAddress({
          redirectAddress: getLoginLink(path),
          hasReturnUrl: hasReturnUrl(path)
        });
      }
      if (!user.isCorporate()) {
        throw new ForbiddenAddress({
          redirectAddress: commonRoutes.home.to
        });
      }
      return privateLayout;
    };
