import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { getSkillRoles } from '../store/skills/skills.services';
import { hackyServerSideTranslations } from '../i18nNext';
import * as Sentry from '@sentry/nextjs';
import { buildSimpleHead } from '../components/layout/heading';
import { buildGuestOnlyLayout } from '../components/layout/layoutBuilder';
import { cdnize } from '../utils/pureUtils';
import { getSlugsWithCompany } from '../store/missions/missions.service';

const NextJsHomePage = dynamic(() => import('../components/homePage/HomePage'));

const HomePage = ({ roles, missions }) => {
  return <NextJsHomePage roles={roles} missions={missions} />;
};

HomePage.getHead = () => props => {
  const DefaultHeader = buildSimpleHead('general');

  const imageLink = cdnize('/meta/beelance-facebook-meta.jpg');
  const twitterImageLink = cdnize('/meta/beelance-twitter-meta.jpg');

  return <DefaultHeader {...props} twitterImageLink={twitterImageLink} imageLink={imageLink} />;
};

HomePage.propTypes = {
  roles: PropTypes.array,
  missions: PropTypes.array
};

export async function getStaticProps({ locale }) {
  const i18nConfig = await hackyServerSideTranslations(locale);
  try {
    const roles = await getSkillRoles();
    const missions = await getSlugsWithCompany();
    return { props: { roles, missions, ...i18nConfig } };
  } catch (e) {
    Sentry.captureException(e);
    return { props: { roles: [], missions: [], ...i18nConfig } };
  }
}

HomePage.getLayout = buildGuestOnlyLayout();
export default HomePage;
